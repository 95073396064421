@import "src/styles/colors";
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

body {
  // font-family: "Courier New", Courier, monospace;
  //   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  // "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  // "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-family: "Montserrat", sans-serif;
 //background-color: white !important;
}

.removeExtraBotMargin {
  margin-bottom: 0;
}

.newContainer {
  padding-left: 1em;
  padding-right: 1em;
}

.fixedBottomContainer {
  padding-top: 1em;
  padding-bottom: 1em;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: $containerWhite;
  //box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.heading {
  font-weight: 900;
}
.signHeader{
  display: flex;
  align-items: center;
  color: #999;
}
