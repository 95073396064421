.noRowBorder td {
  border-top: none;
}
ul {
  list-style: none;
  position: relative;
  left: -30px;
}

.draft {
  color: #0098C8;
  font-size: 14px;
  font-weight: 500;
}

.submitted {
  color: #80BE41;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px ;

}

.pen {
  height: 32px;
  width: 32px;
  position: absolute;
  top:20px;
  right:-20px;
  
}

.right {
  height: 32px;
  width: 32px;
  position: absolute;
  top:20px;
  right:-20px;
}
.details{
 
  font-size: 16px;
  font-weight: 700;
}
.dateinfo{
  font-size:14px;
   font-weight: 700;
  color: #959595;
  margin-top: 4px;

 
}
.line{
  padding: 0px !important;
  margin-left:-25px !important;
  margin-right:-45px !important;
  
}
.studentBox{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #999;
  margin-left: -12px;
  margin-right: -12px;
  padding: 10px 15px 0px 15px;
  .pencilButton {
    button{
    background: #fff;
    border: none;
    height: 20px;
    width: 20px;
    margin-right: 20px;
    }
    img{
    height: 35px;
    width: 35px;
    object-fit: contain;
    margin-top: -16px;
    }
  }
}
