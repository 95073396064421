body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-loginform{
  background: #ffffff;
}

.bg-grey{
  background: #edeff5;
}

.login-form-container{
  /* background: #0098c8; */
  min-height: 100vh;
  margin: 0;
  position: absolute;
  /* top: 60%; */
  /* left: 50%; */
  /* margin-right: -50%; */
  padding: 0 !important;
  /* transform: translate(-50%, -50%)  */
}

.form-card{
  height: max-content;
  /* min-height: 80vh; */
  padding-top: 2rem;
  padding-bottom: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.form-width-inherit{
  width: inherit;
}

.card-header{
  height: max-content;
}

.logo{
  width: inherit;
  max-width: 200px;
}

.login-btn{
  width: 100%;
  height: 60px;
  text-transform: uppercase;
}

.login-btn:hover {
  background: #017da5;
  color: white;
}

.halfBtn{
  background: #0098c8;
  color: white;
  width: 100%;
  margin-bottom: 10px;
}
.halfBtn:hover{
  background: #017da5;
  color: white;
}
.halfBtn:focus{
  outline: none;
  box-shadow: none;
}
.centerBtn{
  width: 140px !important;
  margin-right: 25px  !important;
}

.btn{
  width: 140px !important;
}

input{
  border:none;
  background-color: transparent;
  
}
input:active{
  box-shadow: white;
}

input:focus,
input:active,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.fa-user-circle-o {
  color: gray;
}

.input-group {
  border: 1px gray solid;
  padding: 10px;
}
.input-group{
    background: white;
    border: 2px solid #CCCCCC;
    border-radius: 5px;
}
.icon {
    color: #A5A5A5;
    padding-top: 12px;
}
.form-control{
    border: 0;
}
.form-control:focus,
.form-control:valid,
.form-control:invalid{
    box-shadow: unset;
}


.btn-disabled {
    background: #CCCCCC;
    pointer-events: none;
}

.btn.disabled, .btn:disabled{
    background: #CCCCCC;
    border-color: transparent;
}

.color-active{
    color: #0098c8;
}

.bg-active{
    background: #0098c8;
}
.border-active {
    border: 2px solid #0098c8
}

.border-error{
    border: 2px solid red;
}

.border-success{
    border: 2px solid green;
}

.text-gray {
    color: #707070;
}

.text-align-right{
    text-align: right;
}

.disabled{
    opacity: 0.5;
    cursor: not-allowed !important;
    pointer-events: none !important;
}
.enabled {
    opacity: 1;
    cursor: default;
}
.loader {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
}

.modal{
  background: rgba(79, 78, 78, 0.8);
}

.modal.show .modal-dialog {
  top: auto;
}

.modal-dialog {
    width: 95%;
    user-select: none;
}
.signHeader{
  button{
    background: #fff;
    border: none;
  }
}
@media screen and (min-width:576px){
  .modal-dialog{
    max-width: 550px;
  }
}
.center-width{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (max-width:460px){
  .center-width{
    display: flex;
    width: 99%;
    flex-wrap: wrap;
    justify-content: center;
  }
  .centerBtn{
    margin-right: 0px !important;
  }
}
