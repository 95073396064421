.worklog-container{
  padding:0px !important;
  margin: 0px !important;
}

.subheading {
    color: #333333;
    font-size: 17px;
    padding-left: 17px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 700;

}

.leftImg {
    width: 32px;
    height: 32px;
    cursor: pointer;
    position: relative;
    top:-15px;
    left: -20px;
  }
  .subarea{
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  
  .logoImg {
    padding-right: 10px;
    width: 130px;
    height: 36px;
    position: relative;
    top:-15px;
    margin-left: auto;
  }
  
  .activity {
    font-size: 21px;
    position: relative;
    left: 5px;
    top:-15px;
    font-weight: 700;
  }
  .nav{
    height: 40px;

    padding-bottom: 0px !important;
    margin-bottom: 0px !important;

  }
  .loaderImage{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

